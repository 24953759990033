<template>
  <div class="containerj">
    <img src="../assets/Logo2.png" alt="Logo" class="logo">
    <form class="form" @submit.prevent="checkInput">
      <input type="text" v-model="codeInput" class="code-input" placeholder="Enter your code" required>
      <button type="submit" class="submit-button">Submit</button>
    </form>
    <div class="link-container">
      <router-link to="/connexion">Page de connexion au Biotag Cloud <v-icon>mdi-arrow-right</v-icon></router-link>
    </div>
    
  </div>
</template>


<script>
export default {
  name: 'Login',
  data() {
    return {
      codeInput: '',
      color: 'success',
      errors: [],
      EmailExistenceLength: 0,
    }
  },

  methods: {
    async checkInput() {
      console.log('hello')
      console.log(this.codeInput)
      if (!this.codeInput) {
        this.errors.push(' please enter all fields')
        window.alert(this.errors)
        return
      } else {
        await this.CheckCodeExistence() // Utiliser await pour attendre la résolution de la promesse
        if (this.EmailExistenceLength > 0) {
          this.generateCode()
          await this.AddOneToNumber();
        } else {
          this.errors.push(' code Invalid')
          window.alert(this.errors)
          return
        }
      }
    },

    async CheckCodeExistence() {
      const code = encodeURIComponent(this.codeInput)
      const url = this.$api.getRESTApiUri() + `/check-code-existence/${code}`
      return fetch(url)
        .then(res => res.text())
        .then((result) => {
          const data = JSON.parse(result)
           if(data[0].number_of_uses >= data[0].max_of_uses){
            window.alert('Code expiré')
            return;
          }
          this.EmailExistenceLength = data.length
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async generateCode() {
      const code = JSON.parse(this.codeInput)
      localStorage.setItem('code', JSON.stringify(code))
      this.$router.push({ name: 'home' })
    },
    async AddOneToNumber(){
				const url= this.$api.getRESTApiUri() + `/add-one`;
				return fetch(url, {
						method: 'PUT',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							code: this.codeInput
						}),
					})
				// Converting to JSON
				.then(response => response.json())
				// Displaying results to console
				.then(json => console.log(json));
			},
  },
}
</script>

<style>
.containerj {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.logo {
  max-width: 400px;
  margin-bottom: -20px;
}

.form {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.code-input {
  width: 300px;
  height: 40px;
  border-radius: 20px;
  padding: 10px;
  border: none;
  margin-bottom: 10px;
}

.submit-button {
  background-color: var(--vertfonce);
  color: white !important;
  border: none;
  margin-left: 5px;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: white !important;
  color: var(--vertfonce) !important;
}
.link-container{
  margin-top: 450px;
  width: 100%;
  display: flex;
  justify-content: right;
  padding-right: 5%;
}

</style>